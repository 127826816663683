#profile-details {
  .ant-dropdown {
    .ant-dropdown-menu {
      padding: 32px;
      @media @screen767 {
        margin-top: 16px;
      }
      .ant-dropdown-menu-item {
        padding: 0;
        background-color: transparent;
        &:hover {
          background: var(--color-neturals10);
        }
        .ant-dropdown-menu-title-content {
          .profile-details {
            .text-name {
              color: var(--color-neturals2);
            }
            .text-email {
              .bodyS();
              color: var(--color-neturals4);
              margin-bottom: 24px;
              @media @screen767 {
                margin-bottom: 12px;
              }
            }
          }
          .text-sign-out {
            .bodyS();
            color: var(--color-primary);
            margin-top: 24px;
            font-weight: 600;
            width: max-content;
            cursor: pointer;
            @media @screen767 {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}

.edit-profile-modal {
  .ant-modal-header {
    .ant-modal-title {
      .edit-profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          .bodyL(600);
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }
  .edit-profile-form {
    margin-top: 24px;
    .ant-form-item {
      .ant-form-item-label {
        padding: 0;
        label {
          height: 24px;
          margin-bottom: 12px;
          &::before {
            all: unset;
          }
          &::after {
            display: inline-block;
            margin-inline-end: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
            visibility: visible;
          }
          .bodyMeta();
          @media @screen767 {
            margin-bottom: 4px;
          }
        }
      }
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input {
            background-color: var(--color-neturals10);
            &.ant-input-disabled {
              background-color: var(--color-neturals8);
            }
            &.ant-input-lg {
              .bodyS();
            }
          }
          .ant-btn-primary {
            &:disabled {
              opacity: 0.4;
              background: var(--color-primary);
              color: var(--color-neturals10);
            }
          }
        }
      }
    }
  }
}
