.container {
  width: 100%;
  max-width: @container;
  margin: 0 auto;
  padding: 0 24px;

  @media @screen1200 {
    padding: 0 20px;
  }
  @media @screen767 {
    padding: 0 16px;
  }
}

.page-flex {
  display: inline-flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: space-between;
  .wrapper {
    flex: 1;
    position: relative;
    max-height: calc(100vh - 147px);
    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
    min-height: 390px;
    @media @screen767 {
      max-height: calc(100vh - 196px);
    }
    &.active {
      max-height: calc(100vh - 88px);
    }
    &.not-found-wrapper,
    &.auth-wrapper {
      max-height: calc(100vh - 88px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.bg-app {
    background: radial-gradient(
      81.27% 164.39% at 50% -26.48%,
      rgba(0, 153, 217, 0) 0%,
      rgba(0, 153, 217, 0) 40.88%,
      rgba(0, 153, 217, 0.06) 70.17%,
      rgba(0, 153, 217, 0.17) 83.23%,
      rgba(0, 153, 217, 0.28) 98.4%
    );
  }
  .absolute-bottom {
    position: fixed;
    width: 100%;
    bottom: -8px;
    z-index: 1;
    height: 168px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) -4.84%,
      #fff 37.72%
    );
    @media @screen767 {
      height: 130px;
    }
  }
}
