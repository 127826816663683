.common-button {
  &.ant-btn {
    &.ant-btn-lg {
      padding: 0 20px;
      &:disabled {
        opacity: 0.4;
        background: var(--color-primary);
        color: var(--color-neturals10);
      }
      @media @screen767 {
        padding: 0 16px;
        height: 40px;
      }
    }
    &:disabled {
      opacity: 0.4;
      background: var(--color-primary);
      color: var(--color-neturals10);
    }
  }
}
