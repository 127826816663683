h1,
.h1 {
  .headingOne();
  &.medium {
    .headingOne(700);
  }
}

h2,
.h2 {
  .headingTwo();
  &.medium {
    .headingTwo(700);
  }
}

h3,
.h3 {
  .headingThree();
  &.medium {
    .headingThree(700);
  }
}

h4,
.h4 {
  .headingFour();
  &.medium {
    .headingFour(700);
  }
}

h5,
.h5 {
  .headingFive();
  &.medium {
    .headingFive(700);
  }
}

h6,
.h6 {
  .headingSix();
  &.medium {
    .headingSix(700);
  }
}

.body-l {
  .bodyL();
  &.medium {
    .bodyL(600);
  }
}
.body-xl {
  .bodyL(700);
  &.medium {
    .bodyL(600);
  }
}

.body-m {
  .bodyM();
  &.medium {
    .bodyM(600);
  }
}

.body-s {
  .bodyS();
  &.medium {
    .bodyS(600);
  }
}

.body-base {
  .bodyBase();
  &.medium {
    .bodyBase(600);
  }
}

.body-meta {
  .bodyMeta();
  &.medium {
    .bodyMeta(600);
  }
}

.text-neturals0 {
  color: var(--color-neturals0);
}

.text-neturals1 {
  color: var(--color-neturals1);
}

.text-neturals2 {
  color: var(--color-neturals2);
}

.text-neturals3 {
  color: var(--color-neturals3);
}

.text-neturals4 {
  color: var(--color-neturals4);
}

.text-neturals5 {
  color: var(--color-neturals5);
}

.text-neturals6 {
  color: var(--color-neturals6);
}

.text-neturals7 {
  color: var(--color-neturals7);
}

.text-neturals8 {
  color: var(--color-neturals8);
}

.text-neturals9 {
  color: var(--color-neturals9);
}

.text-neturals10 {
  color: var(--color-neturals10);
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}
