* {
  font-family: var(--font-primary);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: var(--font-primary);
  color: var(--color-neturals0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
