:root {
  --color-primary: #df3c26;
  --color-secondary: #0099d9;
  --color-primary-light: #df3c26bd;
  --color-black: #000000;
  --color-success: #389e0d;
  --color-error: #ff0000;
  --color-unprocessed: #e19617;
  --color-in-progress: #0505f0;
  --bg-light-black: #0000000f;
  --color-neturals0: #000000;
  --color-neturals1: #262428;
  --color-neturals2: #39373c;
  --color-neturals3: #5d5a63;
  --color-neturals4: #7a7683;
  --color-neturals5: #aca9b1;
  --color-neturals6: #ceccd2;
  --color-neturals7: #e6e5e9;
  --color-neturals8: #f5f4f8;
  --color-neturals9: #faf9fc;
  --color-neturals10: #ffffff;
  --color-result-error: #fcefed;
  --color-logicwind: #ef3f3e;
  --color-pink: #ec8b7e;
  --color-blue: #0f62fe;
  --color-blue-02: #0063f7;
  // fonts list
  --font-primary: 'Proxima Nova', sans-serif;
  --font-secondary: 'Poppins', sans-serif;
}

@container: 1440px;
@chatContainer: 1112px;
@searchContainer: 750px;

@screen1450: ~'only screen and (max-width: 1450px)';
@screen1200: ~'only screen and (max-width: 1200px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen425: ~'only screen and (max-width: 425px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen350: ~'only screen and (max-width: 350px)';
@screen320: ~'only screen and (max-width: 320px)';
@screen300: ~'only screen and (max-width: 300px)';
