.home-wrapper {
  padding: 100px 0;
  max-width: 1136px;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - 196px);
  display: flex;
  align-items: center;
  .home-screen {
    .h1 {
      text-align: center;
      max-width: @searchContainer;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .text-highlight {
        color: var(--color-primary);
        font-weight: 700;
      }
    }
    .input-container {
      max-width: @searchContainer;
      margin: 0 auto;
      bottom: unset;
      transition: all 0.5s linear;
      top: 50%;
      left: 50%;
      transition: top 0.5s ease, left 0.5s ease;
      padding-top: 90px;
      width: 90%;

      @media @screen767 {
        padding-top: 16px;
      }

      .ant-input-outlined {
        padding: 10px 52px 10px 60px;
        border-radius: 100px;
        border-color: var(--color-neturals6);
        &.active {
          background: linear-gradient(
                var(--color-neturals8),
                var(--color-neturals8)
              )
              padding-box,
            linear-gradient(to right, var(--color-secondary), var(--color-pink))
              border-box;
          border: 1px solid transparent;
          background-color: var(--color-neturals8);
        }

        @media @screen767 {
          height: 60px;
          border-radius: 16px;
        }
      }
      textarea {
        .bodyM();
      }
      &.active {
        position: fixed;
        top: 88%;
        transform: translate(-51%, -51%);
        z-index: 2;
        @media @screen767 {
          top: 92%;
        }
      }
      .icon-left {
        position: absolute;
        top: 10px;
        left: 24px;
        z-index: 1;
      }
      .icon-right {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        font-size: 32px;
        &.disabled {
          path {
            fill: var(--color-neturals6);
          }
        }
      }
    }
  }

  .chat-screen {
    .chat-box-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      row-gap: 40px;
      margin: 0 auto 48px;
      @media @screen767 {
        row-gap: 24px;
      }
      .chat-right {
        display: flex;
        justify-content: flex-end;
        .text-search {
          border-radius: 50px 50px 12px 50px;
          background: var(--color-neturals8);
          padding: 10px 32px;
          max-width: 450px;
          .bodyS();
          @media @screen767 {
            padding: 12px 16px;
            border-radius: 32px 32px 0px 32px;
          }
        }
      }
      .chat-left {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;
        border: 1px solid var(--color-neturals7);
        border-radius: 2px 40px 40px 40px;
        padding: 24px;
        max-width: @chatContainer;
        @media @screen767 {
          padding: 16px;
        }
        .keyword-container {
          .title {
            padding-left: 12px;
          }
          .keyword-row-container {
            padding: 0 12px;
            .card-wrapper {
              .image-wrapper {
                .image-container {
                  position: relative;
                  .floating-video-pause-icon {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                  .floating-tag {
                    position: absolute;
                    z-index: 1;
                    top: 12px;
                    left: 12px;
                    background-color: var(--color-neturals10);
                    padding: 4px 12px;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    .bodyMeta();
                    @media @screen767 {
                      line-height: 4px;
                    }
                  }
                }
                img {
                  width: 100%;
                  border-radius: 8px;
                  position: relative;
                  object-fit: cover;
                  opacity: 1;
                  background-color: var(--color-neturals6);
                  &.active {
                    opacity: 0;
                  }
                  &.overlay {
                    filter: brightness(0.5);
                  }
                  @media @screen425 {
                    width: 100%;
                    height: 160px;
                  }
                }
                .highlight {
                  color: #e76b5b;
                }
                .description {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical;
                  color: #4c4c4c;
                  p {
                    color: #e76b5b;
                    display: inline-flex;
                  }
                }
                .wrapper-title {
                  overflow: hidden;
                  font-weight: 700;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  p {
                    color: #e76b5b;
                    display: inline;
                  }
                }
              }
            }
          }
          .keyword-view-all {
            margin-top: 24px;
            height: auto;
            padding: 16px;
            border-radius: 100px;
            border-color: #f2f2f2;
            background: #f2f2f2;
            span {
              .h6;
              color: var(--color-blue-02);
            }
          }
        }
        .ai-generated-keyword-result {
          margin-bottom: -24px;
        }
        .content-box {
          .text-description {
            .bodyBase();
            .text-link {
              text-decoration: none;
              color: var(--color-blue);
              .bodyBase(600);
            }
          }
        }
        .suggestion-section {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          .suggestion-title {
            .bodyM(600);
          }
          .suggestion-list {
            .suggestion-item {
              border-bottom: 1px solid var(--color-neturals7);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;
              &:last-child {
                border: 0;
              }
              .text-question {
                .bodyBase();
                color: var(--color-neturals4);
              }
              .suggestion-icon {
                &:hover {
                  path {
                    fill: var(--color-secondary);
                  }
                }
              }
            }
          }
        }
        .chat-skeleton {
          max-width: 638px;
          .ant-skeleton-content {
            .ant-skeleton-title {
              height: 20px;
              background: linear-gradient(
                90deg,
                #0099d930 25%,
                #ec8b7e42 37%,
                #0099d930 63%
              );
              animation-name: shimmer;
              border-radius: 50px;
              background-size: 400% 100%;
            }
            .ant-skeleton-paragraph {
              margin-block-start: 16px;
              li {
                margin-block-start: 8px;
                border-radius: 50px;
                background: linear-gradient(
                  90deg,
                  #0099d930 25%,
                  #ec8b7e42 37%,
                  #0099d930 63%
                );
                animation-name: shimmer;
                background-size: 400% 100%;
                .animation-shimmer();
                &:nth-child(2) {
                  width: 90%;
                }
                &:nth-child(3) {
                  width: 95%;
                }
                &:nth-child(4) {
                  width: 40% !important; // to overwrite inline style
                }
              }
            }
          }
        }
        &.result-error {
          background-color: var(--color-result-error);
          margin-bottom: 32px;
          @media @screen767 {
            margin-bottom: 24px;
          }
          .text-result-error {
            .bodyBase();
          }
        }
      }
    }
  }
  .section {
    transition: opacity 0.5s ease;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
}

.player-modal {
  border: 1px solid var(--color-neturals10);
  border-radius: 8px;
  .ant-modal-content {
    padding: 0;
    .ant-modal-close {
      top: 16px;
      right: 34px;
      @media @screen767 {
        top: 4px;
        right: 16px;
      }
    }
    .ant-modal-header {
      margin: 0;
      .ant-modal-title {
        padding: 16px 40px;
        .bodyM(600);
        .player-modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 8px;
          .icon-wrapper {
            display: flex;
            align-items: center;
            column-gap: 8px;
            svg {
              cursor: pointer;
            }
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
          }
        }
        @media @screen767 {
          padding: 6px 14px;
        }
      }
    }
    .ant-modal-body {
      .react-player-wrapper {
        .player-content {
          .ant-spin-spinning {
            max-height: 100%;
          }
          .player-container {
            height: 70vh !important; // to overwrite inline style
            @media @screen767 {
              height: 40vh !important; // to overwrite inline style
            }
          }
          &.audio {
            padding: 16px 40px;
            .player-container {
              height: 10vh !important; // to overwrite inline style
            }
            @media @screen767 {
              padding: 6px 14px;
            }
          }
          iframe {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
      }
    }
  }
}

.keyword-search-modal {
  .ant-modal-content {
    .ant-modal-body {
      height: 80vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 24px;
      .keyword-row-container {
        padding: 0 12px;
        .card-wrapper {
          // height: 292px;
          .image-wrapper {
            .image-container {
              position: relative;
              .floating-video-pause-icon {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .floating-tag {
                position: absolute;
                z-index: 1;
                top: 12px;
                left: 12px;
                background-color: var(--color-neturals10);
                padding: 4px 12px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                .bodyMeta();
                @media @screen767 {
                  line-height: 4px;
                }
              }
            }
            img {
              width: 100%;
              border-radius: 8px;
              position: relative;
              object-fit: cover;
              opacity: 1;
              background-color: var(--color-neturals6);
              &.active {
                opacity: 0;
              }
              &.overlay {
                filter: brightness(0.5);
              }
              @media @screen425 {
                width: 100%;
                height: 160px;
              }
            }
            .highlight {
              color: #e76b5b;
            }
            .description {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              color: #4c4c4c;
              p {
                color: #e76b5b;
                display: inline-flex;
              }
            }
            .wrapper-title {
              overflow: hidden;
              font-weight: 700;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              p {
                color: #e76b5b;
              }
            }
          }
        }
      }
    }
  }
}
