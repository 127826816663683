// Font size mixins
.headingOne(@weight: 600) {
  font-size: 64px;
  font-style: normal;
  font-weight: @weight;
  line-height: 76px;

  @media @screen767 {
    font-size: 36px;
    line-height: 44px;
  }
}

.headingTwo(@weight: 600) {
  font-size: 48px;
  font-style: normal;
  font-weight: @weight;
  line-height: 58px;

  @media @screen767 {
    font-size: 28px;
    line-height: 34px;
  }
}

.headingThree(@weight: 600) {
  font-size: 40px;
  font-style: normal;
  font-weight: @weight;
  line-height: 48px;

  @media @screen767 {
    font-size: 24px;
    line-height: 32px;
  }
}

.headingFour(@weight: 600) {
  font-size: 32px;
  font-style: normal;
  font-weight: @weight;
  line-height: 38px;

  @media @screen767 {
    font-size: 20px;
    line-height: 30px;
  }
}

.headingFive(@weight: 600) {
  font-size: 24px;
  font-style: normal;
  font-weight: @weight;
  line-height: 32px;

  @media @screen767 {
    font-size: 16px;
    line-height: 24px;
  }
}

.headingSix(@weight: 600) {
  font-size: 20px;
  font-style: normal;
  font-weight: @weight;
  line-height: 30px;

  @media @screen767 {
    font-size: 14px;
    line-height: 22px;
  }
}

.bodyL(@weight: 400) {
  font-size: 22px;
  font-style: normal;
  font-weight: @weight;
  line-height: 32px;

  @media @screen767 {
    font-size: 18px;
    line-height: 28px;
  }
}

.bodyM(@weight: 400) {
  font-size: 20px;
  font-style: normal;
  font-weight: @weight;
  line-height: 30px;

  @media @screen767 {
    font-size: 16px;
    line-height: 24px;
  }
}

.bodyS(@weight: 400) {
  font-size: 18px;
  font-style: normal;
  font-weight: @weight;
  line-height: 28px;

  @media @screen767 {
    font-size: 14px;
    line-height: 20px;
  }
}

.bodyBase(@weight: 400) {
  font-size: 16px;
  font-style: normal;
  font-weight: @weight;
  line-height: 22px;

  @media @screen767 {
    font-size: 12px;
    line-height: 18px;
  }
}

.bodyMeta(@weight: 400) {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media @screen767 {
    font-size: 10px;
    line-height: 24px;
  }
}
