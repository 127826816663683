header {
  border-bottom: 1px solid var(--color-neturals7);
  .header-wrapper {
    display: flex;
    align-items: center;
    min-height: 60px;
    svg {
      cursor: pointer;
    }
    .profile-details {
      display: flex;
    }
  }
}

footer {
  background-color: var(--color-neturals9);
  padding-block: 16px;
  @media @screen767 {
    padding-block: 24px;
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media @screen767 {
      flex-direction: column;
      gap: 12px;
    }
    .text-copyright {
      color: var(--color-neturals4);
      .bodyS();
      @media @screen767 {
        text-align: center;
        .bodyBase();
      }
    }
    .logicwind-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex-wrap: wrap;
      .text-company {
        font-family: 'Poppins', sans-serif;
        .bodyS(600);
        @media @screen767 {
          .bodyBase(600);
        }
      }
    }
  }
}

.not-found-wrapper {
  .not-found-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .not-found-title {
      margin-bottom: 16px;
      text-align: center;
    }
    .not-found-description {
      .bodyM(600);
      margin-bottom: 48px;
      text-align: center;
    }
    .btn-home {
      .bodyBase();
      padding: 8px 32px;
      height: auto;
      border-radius: 50px;
      width: 100%;
    }
    a {
      @media @screen425 {
        width: 100%;
      }
    }
  }
}
