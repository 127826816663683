.ant-copy {
  &.ant-copy-top {
    top: auto !important; // to overwrite inline style
    bottom: 40px;
    @media @screen767 {
      bottom: 28px;
    }
  }
  .ant-copy-notice-wrapper {
    .ant-copy-notice-content {
      background: var(--color-neturals1);
      color: var(--color-neturals10);
      .bodyBase();
    }
  }
}
