.slick-slider {
  .slick-arrow {
    &.slick-prev {
      display: block;
      z-index: 1;
      position: absolute;
      top: 65px;
      left: -14px;
      @media @screen425 {
        top: 75px;
      }
      &::before {
        all: unset;
      }
      &.slick-disabled {
        visibility: hidden;
      }
    }
    &.slick-next {
      display: block;
      z-index: 1;
      position: absolute;
      top: 65px;
      right: -4px;
      @media @screen425 {
        top: 75px;
      }
      &::before {
        all: unset;
      }
      &.slick-disabled {
        visibility: hidden;
      }
    }
  }
  .slick-list {
    .asset-card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 220px;
      row-gap: 16px;
      cursor: pointer;
      @media @screen425 {
        max-width: 95%;
      }
      .lazy-load-wrapper {
        position: relative;
        .image-wrapper {
          border-radius: 8px;
          position: relative;
          object-fit: cover;
          transition: opacity 0.3s ease-in-out;
          opacity: 1;
          background-color: var(--color-neturals6);
          &.active {
            opacity: 0;
          }
          &.overlay {
            filter: brightness(0.5);
          }
          @media @screen425 {
            width: 100%;
            height: 160px;
          }
        }
        .floating-tag {
          position: absolute;
          z-index: 1;
          top: 12px;
          left: 12px;
          background-color: var(--color-neturals10);
          padding: 4px 12px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          .bodyMeta();
          @media @screen767 {
            line-height: 4px;
          }
        }
        .floating-zoom {
          position: absolute;
          z-index: 1;
          top: 12px;
          right: 12px;
          cursor: pointer;
          .bodyMeta();
        }
        .floating-seek-bar {
          position: absolute;
          bottom: 12px;
          z-index: 3;
          width: 100%;
          .ant-slider {
            margin: 0 4px;
            .ant-slider-rail {
              border-radius: 8px;
            }
            &.ant-slider-disabled {
              cursor: pointer;
            }
            &.ant-slider-disabled {
              .ant-slider-handle {
                &::after {
                  display: none;
                }
              }
            }
          }
          .custom-tooltip {
            position: absolute;
            top: -26px;
            background-color: var(--color-neturals10);
            padding: 2px 12px;
            border-radius: 50px;
            .floating-icon {
              position: absolute;
              bottom: -14px;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .asset-meta {
        .asset-title {
          .bodyS(600);
          margin-bottom: 4px;
        }
        .asset-link {
          .bodyBase(600);
          color: var(--color-blue);
        }
      }
      .ant-skeleton {
        .ant-skeleton-image {
          width: 220px;
          height: 136px;
          @media @screen425 {
            width: 95%;
            height: 160px;
          }
        }
        .ant-skeleton-content {
          .ant-skeleton-paragraph {
            margin-block-start: 8px;
            li {
              margin-block-start: 12px;
            }
          }
        }
      }
    }
  }
}
