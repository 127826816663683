:root {
  --color-primary: #df3c26;
  --color-secondary: #0099d9;
  --color-primary-light: #df3c26bd;
  --color-black: #000000;
  --color-success: #389e0d;
  --color-error: #ff0000;
  --color-unprocessed: #e19617;
  --color-in-progress: #0505f0;
  --bg-light-black: #0000000f;
  --color-neturals0: #000000;
  --color-neturals1: #262428;
  --color-neturals2: #39373c;
  --color-neturals3: #5d5a63;
  --color-neturals4: #7a7683;
  --color-neturals5: #aca9b1;
  --color-neturals6: #ceccd2;
  --color-neturals7: #e6e5e9;
  --color-neturals8: #f5f4f8;
  --color-neturals9: #faf9fc;
  --color-neturals10: #ffffff;
  --color-result-error: #fcefed;
  --color-logicwind: #ef3f3e;
  --color-pink: #ec8b7e;
  --color-blue: #0f62fe;
  --color-blue-02: #0063f7;
  --font-primary: 'Proxima Nova', sans-serif;
  --font-secondary: 'Poppins', sans-serif;
}
h1,
.h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 76px;
}
@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 36px;
    line-height: 44px;
  }
}
h1.medium,
.h1.medium {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
}
@media only screen and (max-width: 767px) {
  h1.medium,
  .h1.medium {
    font-size: 36px;
    line-height: 44px;
  }
}
h2,
.h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
}
@media only screen and (max-width: 767px) {
  h2,
  .h2 {
    font-size: 28px;
    line-height: 34px;
  }
}
h2.medium,
.h2.medium {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}
@media only screen and (max-width: 767px) {
  h2.medium,
  .h2.medium {
    font-size: 28px;
    line-height: 34px;
  }
}
h3,
.h3 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
}
@media only screen and (max-width: 767px) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 32px;
  }
}
h3.medium,
.h3.medium {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}
@media only screen and (max-width: 767px) {
  h3.medium,
  .h3.medium {
    font-size: 24px;
    line-height: 32px;
  }
}
h4,
.h4 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
@media only screen and (max-width: 767px) {
  h4,
  .h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
h4.medium,
.h4.medium {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}
@media only screen and (max-width: 767px) {
  h4.medium,
  .h4.medium {
    font-size: 20px;
    line-height: 30px;
  }
}
h5,
.h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  h5,
  .h5 {
    font-size: 16px;
    line-height: 24px;
  }
}
h5.medium,
.h5.medium {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  h5.medium,
  .h5.medium {
    font-size: 16px;
    line-height: 24px;
  }
}
h6,
.h6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  h6,
  .h6 {
    font-size: 14px;
    line-height: 22px;
  }
}
h6.medium,
.h6.medium {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  h6.medium,
  .h6.medium {
    font-size: 14px;
    line-height: 22px;
  }
}
.body-l {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .body-l {
    font-size: 18px;
    line-height: 28px;
  }
}
.body-l.medium {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .body-l.medium {
    font-size: 18px;
    line-height: 28px;
  }
}
.body-xl {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .body-xl {
    font-size: 18px;
    line-height: 28px;
  }
}
.body-xl.medium {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .body-xl.medium {
    font-size: 18px;
    line-height: 28px;
  }
}
.body-m {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .body-m {
    font-size: 16px;
    line-height: 24px;
  }
}
.body-m.medium {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .body-m.medium {
    font-size: 16px;
    line-height: 24px;
  }
}
.body-s {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .body-s {
    font-size: 14px;
    line-height: 20px;
  }
}
.body-s.medium {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .body-s.medium {
    font-size: 14px;
    line-height: 20px;
  }
}
.body-base {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  .body-base {
    font-size: 12px;
    line-height: 18px;
  }
}
.body-base.medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  .body-base.medium {
    font-size: 12px;
    line-height: 18px;
  }
}
.body-meta {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .body-meta {
    font-size: 10px;
    line-height: 24px;
  }
}
.body-meta.medium {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .body-meta.medium {
    font-size: 10px;
    line-height: 24px;
  }
}
.text-neturals0 {
  color: var(--color-neturals0);
}
.text-neturals1 {
  color: var(--color-neturals1);
}
.text-neturals2 {
  color: var(--color-neturals2);
}
.text-neturals3 {
  color: var(--color-neturals3);
}
.text-neturals4 {
  color: var(--color-neturals4);
}
.text-neturals5 {
  color: var(--color-neturals5);
}
.text-neturals6 {
  color: var(--color-neturals6);
}
.text-neturals7 {
  color: var(--color-neturals7);
}
.text-neturals8 {
  color: var(--color-neturals8);
}
.text-neturals9 {
  color: var(--color-neturals9);
}
.text-neturals10 {
  color: var(--color-neturals10);
}
.text-primary {
  color: var(--color-primary);
}
.text-secondary {
  color: var(--color-secondary);
}
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
}
@media only screen and (max-width: 1200px) {
  .container {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding: 0 16px;
  }
}
.page-flex {
  display: inline-flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: space-between;
}
.page-flex .wrapper {
  flex: 1;
  position: relative;
  max-height: calc(100vh - 147px);
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  min-height: 390px;
}
@media only screen and (max-width: 767px) {
  .page-flex .wrapper {
    max-height: calc(100vh - 196px);
  }
}
.page-flex .wrapper.active {
  max-height: calc(100vh - 88px);
}
.page-flex .wrapper.not-found-wrapper,
.page-flex .wrapper.auth-wrapper {
  max-height: calc(100vh - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-flex.bg-app {
  background: radial-gradient(81.27% 164.39% at 50% -26.48%, rgba(0, 153, 217, 0) 0%, rgba(0, 153, 217, 0) 40.88%, rgba(0, 153, 217, 0.06) 70.17%, rgba(0, 153, 217, 0.17) 83.23%, rgba(0, 153, 217, 0.28) 98.4%);
}
.page-flex .absolute-bottom {
  position: fixed;
  width: 100%;
  bottom: -8px;
  z-index: 1;
  height: 168px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -4.84%, #fff 37.72%);
}
@media only screen and (max-width: 767px) {
  .page-flex .absolute-bottom {
    height: 130px;
  }
}
.ant-copy.ant-copy-top {
  top: auto !important;
  bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .ant-copy.ant-copy-top {
    bottom: 28px;
  }
}
.ant-copy .ant-copy-notice-wrapper .ant-copy-notice-content {
  background: var(--color-neturals1);
  color: var(--color-neturals10);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  .ant-copy .ant-copy-notice-wrapper .ant-copy-notice-content {
    font-size: 12px;
    line-height: 18px;
  }
}
.slick-slider .slick-arrow.slick-prev {
  display: block;
  z-index: 1;
  position: absolute;
  top: 65px;
  left: -14px;
}
@media only screen and (max-width: 425px) {
  .slick-slider .slick-arrow.slick-prev {
    top: 75px;
  }
}
.slick-slider .slick-arrow.slick-prev::before {
  all: unset;
}
.slick-slider .slick-arrow.slick-prev.slick-disabled {
  visibility: hidden;
}
.slick-slider .slick-arrow.slick-next {
  display: block;
  z-index: 1;
  position: absolute;
  top: 65px;
  right: -4px;
}
@media only screen and (max-width: 425px) {
  .slick-slider .slick-arrow.slick-next {
    top: 75px;
  }
}
.slick-slider .slick-arrow.slick-next::before {
  all: unset;
}
.slick-slider .slick-arrow.slick-next.slick-disabled {
  visibility: hidden;
}
.slick-slider .slick-list .asset-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 220px;
  row-gap: 16px;
  cursor: pointer;
}
@media only screen and (max-width: 425px) {
  .slick-slider .slick-list .asset-card {
    max-width: 95%;
  }
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper {
  position: relative;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .image-wrapper {
  border-radius: 8px;
  position: relative;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  background-color: var(--color-neturals6);
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .image-wrapper.active {
  opacity: 0;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .image-wrapper.overlay {
  filter: brightness(0.5);
}
@media only screen and (max-width: 425px) {
  .slick-slider .slick-list .asset-card .lazy-load-wrapper .image-wrapper {
    width: 100%;
    height: 160px;
  }
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-tag {
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
  background-color: var(--color-neturals10);
  padding: 4px 12px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-tag {
    font-size: 10px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-tag {
    line-height: 4px;
  }
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-zoom {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-zoom {
    font-size: 10px;
    line-height: 24px;
  }
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar {
  position: absolute;
  bottom: 12px;
  z-index: 3;
  width: 100%;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar .ant-slider {
  margin: 0 4px;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar .ant-slider .ant-slider-rail {
  border-radius: 8px;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar .ant-slider.ant-slider-disabled {
  cursor: pointer;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar .ant-slider.ant-slider-disabled .ant-slider-handle::after {
  display: none;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar .custom-tooltip {
  position: absolute;
  top: -26px;
  background-color: var(--color-neturals10);
  padding: 2px 12px;
  border-radius: 50px;
}
.slick-slider .slick-list .asset-card .lazy-load-wrapper .floating-seek-bar .custom-tooltip .floating-icon {
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slick-slider .slick-list .asset-card .asset-meta .asset-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px;
}
@media only screen and (max-width: 767px) {
  .slick-slider .slick-list .asset-card .asset-meta .asset-title {
    font-size: 14px;
    line-height: 20px;
  }
}
.slick-slider .slick-list .asset-card .asset-meta .asset-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: var(--color-blue);
}
@media only screen and (max-width: 767px) {
  .slick-slider .slick-list .asset-card .asset-meta .asset-link {
    font-size: 12px;
    line-height: 18px;
  }
}
.slick-slider .slick-list .asset-card .ant-skeleton .ant-skeleton-image {
  width: 220px;
  height: 136px;
}
@media only screen and (max-width: 425px) {
  .slick-slider .slick-list .asset-card .ant-skeleton .ant-skeleton-image {
    width: 95%;
    height: 160px;
  }
}
.slick-slider .slick-list .asset-card .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  margin-block-start: 8px;
}
.slick-slider .slick-list .asset-card .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph li {
  margin-block-start: 12px;
}
.common-button.ant-btn.ant-btn-lg {
  padding: 0 20px;
}
.common-button.ant-btn.ant-btn-lg:disabled {
  opacity: 0.4;
  background: var(--color-primary);
  color: var(--color-neturals10);
}
@media only screen and (max-width: 767px) {
  .common-button.ant-btn.ant-btn-lg {
    padding: 0 16px;
    height: 40px;
  }
}
.common-button.ant-btn:disabled {
  opacity: 0.4;
  background: var(--color-primary);
  color: var(--color-neturals10);
}
header {
  border-bottom: 1px solid var(--color-neturals7);
}
header .header-wrapper {
  display: flex;
  align-items: center;
  min-height: 60px;
}
header .header-wrapper svg {
  cursor: pointer;
}
header .header-wrapper .profile-details {
  display: flex;
}
footer {
  background-color: var(--color-neturals9);
  padding-block: 16px;
}
@media only screen and (max-width: 767px) {
  footer {
    padding-block: 24px;
  }
}
footer .footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  footer .footer-wrapper {
    flex-direction: column;
    gap: 12px;
  }
}
footer .footer-wrapper .text-copyright {
  color: var(--color-neturals4);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  footer .footer-wrapper .text-copyright {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-wrapper .text-copyright {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) and only screen and (max-width: 767px) {
  footer .footer-wrapper .text-copyright {
    font-size: 12px;
    line-height: 18px;
  }
}
footer .footer-wrapper .logicwind-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}
footer .footer-wrapper .logicwind-logo .text-company {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  footer .footer-wrapper .logicwind-logo .text-company {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-wrapper .logicwind-logo .text-company {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) and only screen and (max-width: 767px) {
  footer .footer-wrapper .logicwind-logo .text-company {
    font-size: 12px;
    line-height: 18px;
  }
}
.not-found-wrapper .not-found-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.not-found-wrapper .not-found-content .not-found-title {
  margin-bottom: 16px;
  text-align: center;
}
.not-found-wrapper .not-found-content .not-found-description {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 48px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .not-found-wrapper .not-found-content .not-found-description {
    font-size: 16px;
    line-height: 24px;
  }
}
.not-found-wrapper .not-found-content .btn-home {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 32px;
  height: auto;
  border-radius: 50px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .not-found-wrapper .not-found-content .btn-home {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 425px) {
  .not-found-wrapper .not-found-content a {
    width: 100%;
  }
}
#root {
  height: 100vh;
}
/********** margin padding function start here **************/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.gap-0 {
  gap: 0px;
}
.h-0 {
  gap: 0px;
}
.p-6 {
  padding: 6px;
}
.pt-6 {
  padding-top: 6px;
}
.pl-6 {
  padding-left: 6px;
}
.pr-6 {
  padding-right: 6px;
}
.pb-6 {
  padding-bottom: 6px;
}
.m-6 {
  margin: 6px;
}
.mt-6 {
  margin-top: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.mr-6 {
  margin-right: 6px;
}
.mb-6 {
  margin-bottom: 6px;
}
.gap-6 {
  gap: 6px;
}
.h-6 {
  gap: 6px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.gap-8 {
  gap: 8px;
}
.h-8 {
  gap: 8px;
}
.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.gap-10 {
  gap: 10px;
}
.h-10 {
  gap: 10px;
}
.p-12 {
  padding: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.m-12 {
  margin: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.gap-12 {
  gap: 12px;
}
.h-12 {
  gap: 12px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.gap-16 {
  gap: 16px;
}
.h-16 {
  gap: 16px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.gap-24 {
  gap: 24px;
}
.h-24 {
  gap: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.gap-32 {
  gap: 32px;
}
.h-32 {
  gap: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.gap-40 {
  gap: 40px;
}
.h-40 {
  gap: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.gap-48 {
  gap: 48px;
}
.h-48 {
  gap: 48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.responsive-table {
  width: 100%;
  overflow: auto;
}
.ant-table .ant-table-tbody .ant-tag {
  display: flex;
  width: max-content;
  font-size: 14px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.pointer {
  cursor: pointer;
}
.pointer-none {
  pointer-events: none;
}
.grab {
  cursor: grab;
}
/********** text alignment start here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
/********** text alignment end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-card-head-title {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-page-header-heading {
    padding: 0;
    min-height: 48px;
  }
}
#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--font-primary);
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-black);
}
#logo img {
  height: 32px;
}
.delete-icon {
  color: var(--color-error);
  cursor: pointer;
  font-size: 16px;
}
.responsive-logo img {
  width: 32px;
  display: flex;
}
.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}
.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
@media only screen and (max-width: 767px) {
  .ant-layout-sider {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -250px;
    z-index: 12;
  }
}
.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 16px !important;
  }
}
header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 32px;
  line-height: 64px;
  background: var(--color-neturals10);
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: var(--color-black);
    opacity: 0.6;
    z-index: 11;
  }
}
/*sidebar custom less end here*/
@media only screen and (max-width: 767px) {
  .ant-layout-footer {
    padding: 16px 50px;
  }
}
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
:hover::-webkit-scrollbar-thumb {
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
}
.ant-body-scroll .ant-card-head {
  position: relative;
  z-index: 1;
}
.ant-body-scroll .ant-page-header-heading {
  min-height: 48px;
}
.ant-body-scroll > .ant-card-body {
  flex: 1 1;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.ant-body-scroll > .ant-card-body .card-body-wrapper {
  height: 100%;
  overflow: auto;
  padding: 24px;
}
.ant-card-small > .ant-card-body .card-body-wrapper {
  padding: 12px;
}
.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}
.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
}
.title svg {
  margin-right: 5px;
  font-size: 16px;
}
/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: var(--color-neturals10);
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}
/*fleg dropdown css end here*/
.ant-divider.ant-divider-dashed {
  border: 0;
  height: 1px;
  background-image: linear-gradient(90deg, var(--color-neturals7), var(--color-neturals7) 50%, transparent 50%, transparent 100%);
  background-size: 15px 1px;
  margin: 0;
}
.position-relative {
  position: relative;
}
.home-wrapper {
  padding: 100px 0;
  max-width: 1136px;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - 196px);
  display: flex;
  align-items: center;
}
.home-wrapper .home-screen .h1 {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-wrapper .home-screen .h1 .text-highlight {
  color: var(--color-primary);
  font-weight: 700;
}
.home-wrapper .home-screen .input-container {
  max-width: 750px;
  margin: 0 auto;
  bottom: unset;
  transition: all 0.5s linear;
  top: 50%;
  left: 50%;
  transition: top 0.5s ease, left 0.5s ease;
  padding-top: 90px;
  width: 90%;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .home-screen .input-container {
    padding-top: 16px;
  }
}
.home-wrapper .home-screen .input-container .ant-input-outlined {
  padding: 10px 52px 10px 60px;
  border-radius: 100px;
  border-color: var(--color-neturals6);
}
.home-wrapper .home-screen .input-container .ant-input-outlined.active {
  background: linear-gradient(var(--color-neturals8), var(--color-neturals8)) padding-box, linear-gradient(to right, var(--color-secondary), var(--color-pink)) border-box;
  border: 1px solid transparent;
  background-color: var(--color-neturals8);
}
@media only screen and (max-width: 767px) {
  .home-wrapper .home-screen .input-container .ant-input-outlined {
    height: 60px;
    border-radius: 16px;
  }
}
.home-wrapper .home-screen .input-container textarea {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .home-screen .input-container textarea {
    font-size: 16px;
    line-height: 24px;
  }
}
.home-wrapper .home-screen .input-container.active {
  position: fixed;
  top: 88%;
  transform: translate(-51%, -51%);
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .home-screen .input-container.active {
    top: 92%;
  }
}
.home-wrapper .home-screen .input-container .icon-left {
  position: absolute;
  top: 10px;
  left: 24px;
  z-index: 1;
}
.home-wrapper .home-screen .input-container .icon-right {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-size: 32px;
}
.home-wrapper .home-screen .input-container .icon-right.disabled path {
  fill: var(--color-neturals6);
}
.home-wrapper .chat-screen .chat-box-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px;
  margin: 0 auto 48px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper {
    row-gap: 24px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-right {
  display: flex;
  justify-content: flex-end;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-right .text-search {
  border-radius: 50px 50px 12px 50px;
  background: var(--color-neturals8);
  padding: 10px 32px;
  max-width: 450px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-right .text-search {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-right .text-search {
    padding: 12px 16px;
    border-radius: 32px 32px 0px 32px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;
  border: 1px solid var(--color-neturals7);
  border-radius: 2px 40px 40px 40px;
  padding: 24px;
  max-width: 1112px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left {
    padding: 16px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .title {
  padding-left: 12px;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container {
  padding: 0 12px;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .image-container {
  position: relative;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-video-pause-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-tag {
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
  background-color: var(--color-neturals10);
  padding: 4px 12px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-tag {
    font-size: 10px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-tag {
    line-height: 4px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper img {
  width: 100%;
  border-radius: 8px;
  position: relative;
  object-fit: cover;
  opacity: 1;
  background-color: var(--color-neturals6);
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper img.active {
  opacity: 0;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper img.overlay {
  filter: brightness(0.5);
}
@media only screen and (max-width: 425px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper img {
    width: 100%;
    height: 160px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .highlight {
  color: #e76b5b;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #4c4c4c;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .description p {
  color: #e76b5b;
  display: inline-flex;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .wrapper-title {
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-row-container .card-wrapper .image-wrapper .wrapper-title p {
  color: #e76b5b;
  display: inline;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-view-all {
  margin-top: 24px;
  height: auto;
  padding: 16px;
  border-radius: 100px;
  border-color: #f2f2f2;
  background: #f2f2f2;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-view-all span {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: var(--color-blue-02);
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-view-all span {
    font-size: 14px;
    line-height: 22px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-view-all span.medium {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .keyword-container .keyword-view-all span.medium {
    font-size: 14px;
    line-height: 22px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .ai-generated-keyword-result {
  margin-bottom: -24px;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .content-box .text-description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .content-box .text-description {
    font-size: 12px;
    line-height: 18px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .content-box .text-description .text-link {
  text-decoration: none;
  color: var(--color-blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .content-box .text-description .text-link {
    font-size: 12px;
    line-height: 18px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-title {
    font-size: 16px;
    line-height: 24px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-list .suggestion-item {
  border-bottom: 1px solid var(--color-neturals7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-list .suggestion-item:last-child {
  border: 0;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-list .suggestion-item .text-question {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-neturals4);
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-list .suggestion-item .text-question {
    font-size: 12px;
    line-height: 18px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .suggestion-section .suggestion-list .suggestion-item .suggestion-icon:hover path {
  fill: var(--color-secondary);
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton {
  max-width: 638px;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton .ant-skeleton-content .ant-skeleton-title {
  height: 20px;
  background: linear-gradient(90deg, #0099d930 25%, #ec8b7e42 37%, #0099d930 63%);
  animation-name: shimmer;
  border-radius: 50px;
  background-size: 400% 100%;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  margin-block-start: 16px;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton .ant-skeleton-content .ant-skeleton-paragraph li {
  margin-block-start: 8px;
  border-radius: 50px;
  background: linear-gradient(90deg, #0099d930 25%, #ec8b7e42 37%, #0099d930 63%);
  animation-name: shimmer;
  background-size: 400% 100%;
}
@keyframes shimmer {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton .ant-skeleton-content .ant-skeleton-paragraph li:nth-child(2) {
  width: 90%;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton .ant-skeleton-content .ant-skeleton-paragraph li:nth-child(3) {
  width: 95%;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left .chat-skeleton .ant-skeleton-content .ant-skeleton-paragraph li:nth-child(4) {
  width: 40% !important;
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left.result-error {
  background-color: var(--color-result-error);
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left.result-error {
    margin-bottom: 24px;
  }
}
.home-wrapper .chat-screen .chat-box-wrapper .chat-left.result-error .text-result-error {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  .home-wrapper .chat-screen .chat-box-wrapper .chat-left.result-error .text-result-error {
    font-size: 12px;
    line-height: 18px;
  }
}
.home-wrapper .section {
  transition: opacity 0.5s ease;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.home-wrapper .section.active {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.player-modal {
  border: 1px solid var(--color-neturals10);
  border-radius: 8px;
}
.player-modal .ant-modal-content {
  padding: 0;
}
.player-modal .ant-modal-content .ant-modal-close {
  top: 16px;
  right: 34px;
}
@media only screen and (max-width: 767px) {
  .player-modal .ant-modal-content .ant-modal-close {
    top: 4px;
    right: 16px;
  }
}
.player-modal .ant-modal-content .ant-modal-header {
  margin: 0;
}
.player-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  padding: 16px 40px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .player-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 16px;
    line-height: 24px;
  }
}
.player-modal .ant-modal-content .ant-modal-header .ant-modal-title .player-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
}
.player-modal .ant-modal-content .ant-modal-header .ant-modal-title .player-modal-header .icon-wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.player-modal .ant-modal-content .ant-modal-header .ant-modal-title .player-modal-header .icon-wrapper svg {
  cursor: pointer;
}
.player-modal .ant-modal-content .ant-modal-header .ant-modal-title .player-modal-header p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .player-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    padding: 6px 14px;
  }
}
.player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content .ant-spin-spinning {
  max-height: 100%;
}
.player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content .player-container {
  height: 70vh !important;
}
@media only screen and (max-width: 767px) {
  .player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content .player-container {
    height: 40vh !important;
  }
}
.player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content.audio {
  padding: 16px 40px;
}
.player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content.audio .player-container {
  height: 10vh !important;
}
@media only screen and (max-width: 767px) {
  .player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content.audio {
    padding: 6px 14px;
  }
}
.player-modal .ant-modal-content .ant-modal-body .react-player-wrapper .player-content iframe {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.keyword-search-modal .ant-modal-content .ant-modal-body {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container {
  padding: 0 12px;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .image-container {
  position: relative;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-video-pause-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-tag {
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
  background-color: var(--color-neturals10);
  padding: 4px 12px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-tag {
    font-size: 10px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .image-container .floating-tag {
    line-height: 4px;
  }
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper img {
  width: 100%;
  border-radius: 8px;
  position: relative;
  object-fit: cover;
  opacity: 1;
  background-color: var(--color-neturals6);
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper img.active {
  opacity: 0;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper img.overlay {
  filter: brightness(0.5);
}
@media only screen and (max-width: 425px) {
  .keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper img {
    width: 100%;
    height: 160px;
  }
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .highlight {
  color: #e76b5b;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #4c4c4c;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .description p {
  color: #e76b5b;
  display: inline-flex;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .wrapper-title {
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.keyword-search-modal .ant-modal-content .ant-modal-body .keyword-row-container .card-wrapper .image-wrapper .wrapper-title p {
  color: #e76b5b;
}
.login-wrapper {
  max-width: 568px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .login-wrapper {
    min-width: 100%;
    height: calc(100vh - 169px);
  }
}
@media only screen and (max-width: 350px) {
  .login-wrapper {
    min-width: 100%;
    height: calc(100vh - 200px);
  }
}
@media only screen and (max-width: 320px) {
  .login-wrapper {
    min-width: 100%;
    height: calc(100vh - 240px);
  }
}
.login-wrapper .login-alert {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-wrapper .form-wrapper {
  padding: 32px;
  border-radius: 8px;
  background: var(--color-neturals10);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}
.login-wrapper .form-wrapper .text-verify {
  font-size: 32px;
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .text-verify {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper {
    padding: 24px 0 16px 0;
    border-radius: 16px;
    box-shadow: none;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .login-wrapper .form-wrapper .form-wrapper-headline {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 58px;
    font-size: 28px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 767px) and only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .form-wrapper-headline {
    font-size: 28px;
    line-height: 34px;
  }
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-label {
  padding: 0;
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-label label {
  height: 24px;
  margin-bottom: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-label label::before {
  all: unset;
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-label label::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  visibility: visible;
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-label label {
    font-size: 10px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-label label {
    margin-bottom: 4px;
  }
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input {
  background-color: var(--color-neturals10);
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input.ant-input-disabled {
  background-color: var(--color-neturals8);
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input.ant-input-lg {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-neturals0);
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input.ant-input-lg {
    font-size: 14px;
    line-height: 20px;
  }
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-otp {
  width: 100%;
  column-gap: 16px;
}
.login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-otp .ant-input {
  height: 48px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-otp .ant-input {
    height: 40px;
  }
}
.login-wrapper .form-wrapper .login-form .otp-countdown .text-resend {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-neturals4);
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .otp-countdown .text-resend {
    font-size: 12px;
    line-height: 18px;
  }
}
.login-wrapper .form-wrapper .login-form .form-fields-wrapper .otp-form-field .ant-form-item-control-input .ant-form-item-control-input-content div {
  gap: 16px;
}
.login-wrapper .form-wrapper .login-form .form-fields-wrapper .otp-form-field .ant-form-item-control-input .ant-form-item-control-input-content input {
  flex: 1;
}
.login-wrapper .form-wrapper .login-form .form-fields-wrapper .otp-form-field .ant-form-item-control-input .ant-form-item-control-input-content .ant-input {
  height: 48px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .form-fields-wrapper .otp-form-field .ant-form-item-control-input .ant-form-item-control-input-content .ant-input {
    height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form .form-fields-wrapper {
    flex: 1;
  }
}
@media only screen and (max-width: 767px) {
  .login-wrapper .form-wrapper .login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
#profile-details .ant-dropdown .ant-dropdown-menu {
  padding: 32px;
}
@media only screen and (max-width: 767px) {
  #profile-details .ant-dropdown .ant-dropdown-menu {
    margin-top: 16px;
  }
}
#profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
  background-color: transparent;
}
#profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: var(--color-neturals10);
}
#profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .profile-details .text-name {
  color: var(--color-neturals2);
}
#profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .profile-details .text-email {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--color-neturals4);
  margin-bottom: 24px;
}
@media only screen and (max-width: 767px) {
  #profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .profile-details .text-email {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  #profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .profile-details .text-email {
    margin-bottom: 12px;
  }
}
#profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .text-sign-out {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--color-primary);
  margin-top: 24px;
  font-weight: 600;
  width: max-content;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  #profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .text-sign-out {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  #profile-details .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .text-sign-out {
    margin-top: 12px;
  }
}
.edit-profile-modal .ant-modal-header .ant-modal-title .edit-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-profile-modal .ant-modal-header .ant-modal-title .edit-profile-header p {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .edit-profile-modal .ant-modal-header .ant-modal-title .edit-profile-header p {
    font-size: 18px;
    line-height: 28px;
  }
}
.edit-profile-modal .ant-modal-header .ant-modal-title .edit-profile-header svg {
  cursor: pointer;
}
.edit-profile-modal .edit-profile-form {
  margin-top: 24px;
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-label {
  padding: 0;
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-label label {
  height: 24px;
  margin-bottom: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-label label::before {
  all: unset;
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-label label::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  visibility: visible;
}
@media only screen and (max-width: 767px) {
  .edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-label label {
    font-size: 10px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-label label {
    margin-bottom: 4px;
  }
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input {
  background-color: var(--color-neturals10);
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input.ant-input-disabled {
  background-color: var(--color-neturals8);
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input.ant-input-lg {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-input.ant-input-lg {
    font-size: 14px;
    line-height: 20px;
  }
}
.edit-profile-modal .edit-profile-form .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-btn-primary:disabled {
  opacity: 0.4;
  background: var(--color-primary);
  color: var(--color-neturals10);
}
